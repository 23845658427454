import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import Spinner from './spinner'
import ImageUploader from './imageUploader'
import toast from 'react-hot-toast'
import { errorStyle,successStyle } from '../utils/toast.format'
import { storage } from '../Firebase'
import axios from 'axios'
import { Buffer } from 'buffer'

const ProfilePicture = ({ profilePhoto,onUpload }) => {
  const [imageUrl,setImageUrl] = useState(``)

  useEffect(async() => {
    try{
      if(!profilePhoto) return setImageUrl(`https://robohash.org/${Math.floor(Math.random() * 20) + 1}?200x200`)
      setImageUrl(profilePhoto)
    }
    catch(error){
      toast(`Something went wrong.`,errorStyle)
    }
  },[profilePhoto])

  const handleImageUpload = async imageInput => {
    setImageUrl(``)
    try{
      const [file] = imageInput.target.files

      const reader = new FileReader()

      reader.onload = async() => {
        const arrayBuffer = reader.result
        const mimeType = file.type
        const jwt = localStorage.getItem(`jwt`)
        const buffer = Buffer.from(arrayBuffer)
        
        const config = {
          method: `POST`,
          headers: {
            Authorization: `Bearer ${jwt}`,
            "Content-Type": mimeType,
          },
          url: `https://api.func.live/upload-buffer`,
          data: buffer,
        }
        const response = await axios(config)
        if(response.status !== 200) throw new Error(`Failed to upload image`)
        onUpload(response.data.url)
        setImageUrl(response.data.url)
      }

      reader.onloadend = () => {
        toast(`Image uploaded!`,successStyle)
      }
      reader.readAsArrayBuffer(file)
    }
    catch(error){
      toast(`Image failed to upload`,errorStyle)
      setImageUrl(`https://robohash.org/${Math.floor(Math.random() * 20) + 1}?200x200`)
    }
  }

  return (
    <Container>
      {!imageUrl && <Spinner size={34} />}
      {imageUrl && <Img alt="profile" src={`${imageUrl}`} />}
      {onUpload && <ImageUploader 
        label="Upload Profile Photo"
        onInput={i => handleImageUpload(i)}/>}
    </Container>
  )
}

export default ProfilePicture

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  flex-grow: 0 !important;
  gap:10px;
  width: 100%;
`
const Img = styled.img`
  height: 240px;
  width: 240px;
  object-fit: cover;
  border-radius: 50%;
`