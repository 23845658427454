import { useUser } from '../hooks/useUser'
import Login from './login'
import { db,parseDocSnapshot } from '../Firebase'
import styled from 'styled-components'
import { useAuthUser } from '../hooks/useAuthUser'
import axios from 'axios'
import { CORE_URL } from '../config.js'
import firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import { useEffect,useState } from 'react'
import Spinner from './spinner.js'

export const Wall = ({ children }) => {

  const [authUser,setAuthUser] = useAuthUser()
  const [loading,setLoading] = useState(true)
  const user = useUser()

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(async user => {
      if (user) {
        const idToken = await user.getIdToken()
        const res = await axios({
          method: `get`,
          url: `${CORE_URL}/token`,
          headers: { Authorization: `Bearer ${idToken}` },
        })
        localStorage.setItem(`jwt`,res.data.jwt)

        const { displayName,email,uid } = user
        const docRef = db.doc(`/users/${email}`)
        const userDoc = parseDocSnapshot(await docRef.get())
        if (
          !userDoc ||
          userDoc.email !== user.email ||
          userDoc.id !== user.email ||
          userDoc.uid !== user.uid ||
          userDoc.displayName !== user.displayName
        ) 
          await docRef.set({ displayName,email,id: email,uid },{ merge: true })
        
        setAuthUser(email)
      } else 
        setAuthUser(``)
      
      setLoading(false)
    })

    return () => unsubscribe()
  },[])

  if(loading) return <Center><Spinner size={5} /></Center>

  if(!authUser) return <Login/>
  return children
}

const Center = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`