import styled from 'styled-components'
import { useUser } from '../hooks/useUser'
import { AiFillCheckCircle } from 'react-icons/ai'
import { primary,seven } from '../utils/colours'
import { A } from './a'
import { httpService } from '../utils/httpService.js'
import { mutate } from 'swr'


export const OnboardingItem = ({ task }) => {
  const { label,id,link } = task
  const user = useUser()

  const handleClick = async() => {
    const update = user?.onboarding?.includes(id)
      ? { onboarding: (user?.onboarding || []).filter(id => id !== task.id) }
      : { onboarding: [...(user?.onboarding || []),task.id] }
    await httpService(`POST`,`/employees/${user.id}`,update)
    mutate(`/employees/${user.email}`,{ ...user,...update })

  }
  const done = !user?.onboarding?.includes(id)
  const color = done ? seven : primary

  return (
    <Container >
      <Check color={color} onClick={handleClick} />
      <Text done={done}>{label}{link && <A href={link.includes(`http`) ? link : `https://${link}`}>{link}</A>}</Text>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
  align-items:flex-start;
  flex-direction:row;
  gap:5vw;
  width:100%;
  margin:0 5vw;
`
const Text = styled.div`
  text-decoration: ${p => !p.done ? `line-through` : ``};
`
const Check = styled(AiFillCheckCircle)`
  font-size:2rem;
  min-width:30px;
  cursor: pointer;
  color:${p => p.color};
`