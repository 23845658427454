import styled from 'styled-components'
import { routes } from '../config'
import { useUser } from '../hooks/useUser'
import { useHistory } from 'react-router-dom'
import SidebarProfilePicture from './SidebarProfilePicture.js'
import { useState } from 'react'
import { db } from '../Firebase'
import {
  one,primary,seven,three,two,white, 
} from '../utils/colours.js'

export const Sidebar = () => {
  const user = useUser()
  const history = useHistory()
  const [state,setState] = useState({})

  const options = routes
    .filter(r => user.email === `andi@wakeflow.io` || !r.adminsOnly)
    .filter(r => {
      if(user.email && r.route === `/login`) return false
      return true
    })
    .filter(r => {
      if(!user.email && r.route === `/logout`) return false
      return true
    })

  const handleClick = async route => {
    if (route.startsWith(`http://`) || route.startsWith(`https://`)) 
      window.open(route,`_blank`) 
    else 
      history.push(route) 
  }
  return (
    <Container>
      <Top>
        <SidebarProfilePicture />
        <Text>
          <WelcomeBack>Welcome back</WelcomeBack>
          <Name>{user.firstname}</Name>
        </Text>
      </Top>
      <Navigation>
        {options
          .filter(item => !item.requiresLogin || user.email)
          .map((option,index) => {
            const { Icon,route,label } = option
            return(
              <NavItem 
                key={index}
                onClick={() => handleClick(route)}>
                <Icon size={`1.2rem`} color={`white`} />
                <Label> {label} </Label>
              </NavItem>
            )
          })
        }
      </Navigation>
    </Container>
  )
}


const Container = styled.div`
  @media screen and (max-width: 768px) {
    display: none;
  }
  position: fixed;
  height: 100%;
  display:flex;
  justify-content: flex-start ;
  align-items: center;
  flex-direction: column;
  width: 20rem;
  background: ${one};
  border-right: 1px solid #edeeed;
`
const Top = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 0.9rem 0.9rem 0 0.9rem;
  height: 6rem;
`
const Navigation = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 0.8rem;
  padding: 1.2rem;
`
const NavItem = styled.div`
  user-select: none;
  display: flex;
  flex-direction: wrap;
  justify-content: flex-start;
  box-sizing:border-box;
  gap: 1.1rem;
  padding: .7rem 1.2rem;
  align-items: center;
  cursor: pointer;
  border-radius: 12px;
  text-decoration: none;
  &.active {
    background: #1a11bb26;
  }
  &.active > * {
    color: ${primary};
    fill: ${primary};
  }
  `
const Label = styled.p`
  align-items: center;
  font-size: 0.9rem;
  margin: 0;
  color: ${white};
  padding: 0;
`
const WelcomeBack = styled.p`
  margin: 0;
  font-size: 0.8rem;
  color: white;
`
const Name = styled.p`
  margin: 0;
  font-size: 1.2rem;
  color: ${primary};
  font-weight:bold;
`
const Text = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`