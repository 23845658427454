import React from 'react'
import ProjectsTable from './projectsTable'
import MainLayout from './mainLayout'
import { useApi } from '../hooks/useApi'

const Projects = () => {
  const projects = useApi(`/projects`) || []
  const clients = useApi(`/companies`) || []

  return(
    <MainLayout>
      <ProjectsTable projects={projects} clients={clients}/>
    </MainLayout>
  ) 
}

export default Projects
