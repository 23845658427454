import React,{ Suspense } from 'react'
import Spinner from '../components/spinner'
import { Switch,Route } from 'react-router-dom'
import Login from '../components/login'
import Logout from '../components/logout'
import Landing from '../components/landing'

import Profile from '../components/profile'
import Employees from '../components/employees'
import ThisMonth from '../components/thisMonth'
import Analytics from '../components/analytics'
import Clients from '../components/clients'
import Projects from '../components/projects'
import Client from '../components/client'
import Project from '../components/project'
import ErrorBoundaryMessage from '../components/errorBoundaryMessage'
import Today from '../components/today'

import { Wall } from '../components/Wall'
import { Onboarding } from '../components/onboarding'
import { UserProfile } from '../components/UserProfile'
import { EmployeeProfile } from '../components/EmployeeProfile'


const Router = () => {
  

  return(
    <Suspense fallback={<Spinner />}>
      <Switch>
        <Route exact path="/login" component={() => <Login/>} />
        <Route exact path="/landing" component={() => <Wall><Landing/></Wall>} /> 
        <Route exact path="/profile" component={() => <Wall><UserProfile/></Wall>} />
        <Route exact path="/employees" component={() => <Wall><Employees/></Wall>}/>
        <Route exact path="/employees/:employeeId" component={() => <Wall><EmployeeProfile/></Wall>}/>
        <Route exact path="/today" component={() => <Wall><Today/></Wall>}/>
        <Route exact path="/" component={() => <Wall><Today/></Wall>}/>
        <Route exact path="/this-month" component={() => <Wall><ThisMonth/></Wall>}/>
        <Route exact path="/client/:clientId" component={() => <Wall><Client/></Wall>}/>
        <Route exact path="/clients" component={() => <Wall><Clients/></Wall>}/>
        <Route exact path="/project/:projectId" component={() => <Wall><Project/></Wall>}/>
        <Route exact path="/projects" component={() => <Wall><Projects/></Wall>}/>
        {/* <Route exact path="/analytics" component={() => <Wall><Analytics/></Wall>}/> */}
        <Route exact path="/onboarding" component={() => <Wall><Onboarding/></Wall>}/>
        <Route exact path="/logout" component={() => <Logout/>} />
        <Route exact path="/error" component={ErrorBoundaryMessage} />
      </Switch>
    </Suspense>
  )
}

export default Router

