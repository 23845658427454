import React from 'react'
import styled from 'styled-components'
import { darkgray,five,four,lightgray } from '../utils/colours'
import 'firebase/auth'
import ProjectItem from './projectItem'
import { useHistory } from 'react-router-dom'
import Button from './button'
import sortArray from 'sort-array'
import { httpService } from '../utils/httpService'
import toast from 'react-hot-toast'
import { successStyle,errorStyle } from '../utils/toast.format'


const ProjectsTable = ({ projects,clients }) => {
  const history = useHistory()
  const handleClick = async() => {
    try {
      const newProject = {
        name: ``,
        status: `inactive`,
      }
      const projectRef = await httpService(`POST`,`/projects`,newProject)
      history.push(`/project/${projectRef.id}`)
      toast(`Project Created!`,successStyle) 
    } catch (error) {
      console.error(error)
      toast(`Error in creating a project!`,errorStyle) 
    }
  }  
  return (
    <Container>
      <Table>
        <thead>
          <tr>
            <Th> Project Name </Th>
            <Th> Client Name </Th>
            <Th> Status </Th>
            <Th></Th>
          </tr>
        </thead>
        <Tbody>
          {projects && sortArray([...projects],{ by: `status` }).map((project,index) => {
            return(
              <ProjectItem project={project} clients={clients} key={index}/>
            )
          })}
        </Tbody>
      </Table>
      <Button 
        onClick={handleClick} 
        css={`margin-top:10px;`}>
        + Project
      </Button>
    </Container>
  )
}

export default ProjectsTable

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80vw;
  color: ${darkgray};
  padding: 20px;
`
const Table = styled.table`
  display: table;
  flex-grow: 1;
  border-collapse: collapse;
  width: 60%;
  @media screen and (max-width: 600px){
    display: block;
    overflow-x: auto;
    width: 80%;
  }
`
const Th = styled.th`
  font-weight: 500;
  font-size: 1.2rem;
  padding:20px;
  border-radius:0;
  text-align: center;
  border-bottom: 2px solid ${darkgray};
  background-color: ${lightgray};
`
const Tbody = styled.tbody`
  tr:nth-child(even){
    background-color: ${lightgray};
    &:hover{
      background-color: ${five};
    }
  }
  tr:nth-child(odd){
    &:hover{
      background-color: ${four};
    }
  }
`
