import { IoMdClipboard } from 'react-icons/io'
import {
  AiOutlineUnorderedList,AiOutlineBook,
  AiOutlineLogin,AiOutlineLogout,AiOutlineUser,AiOutlineTrophy,AiOutlineClockCircle,AiOutlineCalendar,AiOutlinePieChart,AiOutlineHome,
} from 'react-icons/ai'
import { BsPeople } from 'react-icons/bs'
import { MdOutlineHandshake } from 'react-icons/md'

export const firebaseConfig = {
  apiKey: `AIzaSyAbqoOV4OiEFyrtFu8DWQQN6nPVwv_CJC8`,
  authDomain: `wakeflow-team.firebaseapp.com`,
  projectId: `wakeflow-team`,
  storageBucket: `wakeflow-team.appspot.com`,
  messagingSenderId: `809211061912`,
  appId: `1:809211061912:web:7b8bd2dc4896d0941cd2ed`,
  measurementId: `G-12N56LWF8P`,
}

export const CORE_URL = `https://core.wakeflow.io`
// export const CORE_URL = `http://localhost:7654`

export const routes = [
  {
    route: `/landing`,  
    label: `Home`,
    Icon: AiOutlineHome,
  },
  {
    route: `/profile`,
    requiresLogin: true,
    label: `Profile`,
    Icon: AiOutlineUser,
  },
  // {
  //   route: `/goals`,
  //   requiresLogin: true,
  //   label: `Goals`,
  //   Icon: AiOutlineTrophy,
  // },
  {
    adminsOnly: true,
    route: `/employees`,
    requiresLogin: true,
    label: `Employees`,
    Icon: BsPeople,
  },
  {
    route: `/today`,
    requiresLogin: true,
    label: `Today`,
    Icon: AiOutlineClockCircle,
  },
  {
    route: `/this-month`,
    requiresLogin: true,
    label: `This Month`,
    Icon: AiOutlineCalendar,
  },
  {
    adminsOnly: true,
    route: `/clients`,
    requiresLogin: true,
    label: `Clients`,
    Icon: MdOutlineHandshake,
  },
  {
    adminsOnly: true,
    route: `/projects`,
    requiresLogin: true,
    label: `Projects`,
    Icon: IoMdClipboard,
  },
  {
    route: `https://wakeflow.github.io/how-we-do/`,
    requiresLogin: true,
    label: `How we do`,
    Icon: AiOutlineBook,
  },
  {
    adminsOnly: true,
    route: `/analytics`,
    requiresLogin: true,
    label: `Analytics`,
    Icon: AiOutlinePieChart,
  },
  {
    route: `/onboarding`,
    requiresLogin: true,
    label: `Onboarding`,
    Icon: AiOutlineUnorderedList,
  },
  {
    route: `/login`,
    label: `LogIn`,
    Icon: AiOutlineLogin,
  },
  {
    route: `/logout`,
    requiresLogin: true,
    label: `LogOut`,
    Icon: AiOutlineLogout,
  },
]