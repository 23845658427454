export const employeeSchema = {
  type: `object`,
  properties: {
    firstname: { type: `string` },
    lastname: { type: `string` },
    email: { type: `string` },
    dob: { type: `date` },
    phone: { type: `string` },
    address_line_1: { type: `string` },
    address_line_2: { type: `string` },
    city: { type: `string` },
    postcode: { type: `string` },
    country: { type: `string` },
    github: { type: `string` },
    linkedin: { type: `string` },
    start_date: { type: `date` },
    end_date: { type: `date` },
    bank_account_number: { type: `string` },
    sort_code: { type: `string` },
    iban: { type: `string` },
    tax_code: { type: `string` },
    biography: { type: `string` },
    emergency_contact_phone: { type: `string` },
    emergency_contact_name: { type: `string` },
    emergency_contact_relationship: { type: `string` },
  },
}