import { CORE_URL } from '../config.js'
import axios from 'axios'

export const httpService = async(type = `POST`,path,data) => {
  const jwt = localStorage.getItem(`jwt`)
  try {
    const response = await axios({
      method: type,
      url: `${CORE_URL}${path}`,
      headers: { Authorization: `Bearer ${jwt}` },
      data, 
    })
    return response.data
  }catch(err){
    console.log(err)
    throw err
  }
}