import axios from 'axios'
import { CORE_URL } from '../config.js'
import useSWR from 'swr' // Importing the useSWR hook from the SWR library

export const useApi = path => {
  let jwt = localStorage.getItem(`jwt`)
  const { data = false,error } = useSWR((jwt && !path.includes(`undefined`)) ? path : null,() => {
    const fetcher = async() => {
      const axiosConfig = {
        method: `get`,
        url: `${CORE_URL}${path}`,
        headers: { Authorization: `Bearer ${jwt}` },
      }
      console.log(`making call`)
      const { data } = await axios(axiosConfig)
      return data
    }
    return fetcher()
  },{ revalidateOnFocus: true })
  if(error) console.log({ error })
  return data
}