import { db,parseCollectionSnapshot } from '../Firebase'
import { useAtom } from 'jotai'
import { collectionsAtom,colSubscriptions } from './useCollection'
import { useUser } from './useUser.js'
import { useApi } from './useApi.js'

export const useTimes = () => {
  const user = useUser()
  const path = `/times?employee_id=${user?.id || `undefined`}`
  const times = useApi(path) || []
  return times
}
