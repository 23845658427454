import React,{ useState,useEffect,useCallback } from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import { darkgray,pink } from '../utils/colours'
import { useParams,useHistory } from 'react-router-dom'
import Spinner from './spinner'
import { successStyle,errorStyle } from '../utils/toast.format'
import toast from 'react-hot-toast'
import Button from './button'
import { Form } from '@wakeflow/ui'
import { useApi } from '../hooks/useApi'
import { httpService } from '../utils/httpService'

const Project = () => {
  // Get project id from URL
  const { projectId } = useParams()
  const history = useHistory()

  // Get project and clients from API
  const project = useApi(`/projects/${projectId}`)
  const clients = useApi(`/companies`) || []
  
  // Get state and update form
  const getState = () => {
    if(!project) return {}
    const name = project.name || ``
    const clientName = clients.find(c => c.id == project.company_id)?.name || ``
    const status = project.status || ``
  
    return {
      name,
      clientName,
      status,
    }
  }
  
  // Set state
  const [state,setState] = useState({
    name: ``,
    clientName: ``,
    status: ``,
  })
  
  // Update state when project or clients change
  useEffect(() => {
    if(project && clients) setState(getState())
    return () => setState({})
  },[project,clients])

  // Project form schema
  const projectSchema = {
    type: `object`,
    properties: {
      name: { type: `string` },
      clientName: { 
        type: `string`,
        enum: [...Object.values(clients).map(c => c.name),``],
      },
      status: { 
        type: `string`,
        enum: [`active`,`inactive`,`paused`,`closed`], 
        errorMessage: `Must be selected`,
      },
    },
  }
  
  // Contact form schema
  const contactSchema = {
    type: `object`,
    properties: {
      name: { type: `string` },
      address: { type: `string` },
      email: { type: `string` },
    },
  }


  // Handle form submit
  const handleSubmit = async() => {
    try {
      const data = {
        name: state.name,
        company_id: clients.find(c => c.name === state.clientName)?.id,
        status: state.status,
      }
      await httpService(`PUT`,`/projects/${projectId}`,data)
      toast(`Details updated`,successStyle) 
      history.push(`/projects`)
    } catch (error) {
      console.error(error)
      toast(`Failed to update details`,errorStyle)     
    }
  }

  const handleContactChange = change => {
    setState(s => ({ ...s,contact: { ...s.contact,...change } }))
  }

  return (
    <MainLayout>
      <Container>
        {!project && <Spinner/>}
        {project && 
        <>
          <Form 
            values={state} 
            setValues={setState}
            jsonSchema={projectSchema} />
          <HeaderTwo>Con<span>ta</span>ct</HeaderTwo>
          <Form 
            values={state.contact} 
            setValues={handleContactChange}
            jsonSchema={contactSchema} />
          <Button 
            css={`margin-top:10px;`} 
            onClick={handleSubmit}>
            Submit
          </Button>        
        </>}
      </Container>
    </MainLayout>
  )
}

export default Project

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1000px;
  color: ${darkgray};
  padding: 20px;
`
const Header = styled.h1`
  font-size: 3em;
  font-weight: 700;
  ::first-letter{
    color: ${pink};
  }
`
const HeaderTwo = styled.h2`
  font-size: 1.2rem;
  font-weight: 700;
  span {
    color: ${pink};
  }
`