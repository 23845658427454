import styled from 'styled-components'
import Profile from './profile'
import { useParams } from 'react-router-dom'
import { useApi } from '../hooks/useApi.js'
import { addFields } from '../utils/addFields.js'
import { employeeSchema } from '../schemas/employee.schema.js'

export const EmployeeProfile = () => {
  const { employeeId } = useParams()
  const user = useApi(`/employees/${employeeId}`)
  addFields(employeeSchema,user)
  return (
    <Container>
      <Profile user={user}/>
    </Container>
  )
}

const Container = styled.div`
  display:flex;
`