import React from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import { pinkBackground } from '../utils/colours'

const ProfileItem = ({ user }) => {
  const history = useHistory()

  return (
    <Tr
      onClick={() => {
        history.push(`/employees/${user.id}`)
      }}>
      <Td> {user.firstname + ` ` + user.lastname} </Td>
      <Td> {user.email} </Td>
      <Td> {user.phone} </Td>
      <Td> {user.job_title} </Td>
      <Td>{user.role}</Td>
    </Tr>
  )
}

export default ProfileItem

const Td = styled.td`
  text-align: center;
  padding:10px;
  border-radius:0;
`
const Tr = styled.tr`
    cursor: pointer;
  :hover{
    background-color:${pinkBackground};
  }
`