import React,{ useState,useEffect } from 'react'
import styled from 'styled-components'
import MainLayout from './mainLayout'
import { darkgray,green } from '../utils/colours'
import { useParams } from 'react-router-dom'
import Spinner from './spinner'
import toast from 'react-hot-toast'
import { successStyle,errorStyle } from '../utils/toast.format'
import ProjectsTable from './projectsTable'
import Button from './button'
import { useHistory } from 'react-router-dom'
import { Form } from '@wakeflow/ui'
import { useApi } from '../hooks/useApi'
import { httpService } from '../utils/httpService'

const Client = () => {
  const { clientId } = useParams()
  const client = useApi(`/companies/${clientId}`)
  const projects = useApi(`/projects`) || []
  const clientFields = [`name`,`number`,`vatPercentage`,`email`,`contactPhoneNumber`]
  const clientProjects = projects.filter(p => p?.company_id === client?.id)
  const history = useHistory()


  const getState = () => {
    if(!client) return {}
    const name = client.name || ``
    const number = client.number || 0
    const vatPercentage = client.vat_percentage
  
    return {
      name,
      number,
      vatPercentage: vatPercentage * 100,
    }
  }

  const [state,setState] = useState({
    name: ``,
    number: 0,
  })

  useEffect(() => {
    if(client && projects) setState(getState())
    return () => setState({})
  },[client,projects])


  const clientSchema = {
    type: `object`,
    properties: {
      name: { type: `string` },
      number: { type: `number` },
      vatPercentage: { 
        type: `number`,
        minimum: 0,
        maximum: 100,
        errorMessage: `Value needs to be between 0 and 100`,
      },
    },
  }

  const handleSubmit = async() => {
    try {
      await httpService(`PUT`,`/companies/${client.id}`,{
        name: state.name,
        number: state.number,
        vat_percentage: state.vatPercentage / 100,
      })
      toast(`Details updated`,successStyle)
      history.push(`/clients`)
    } catch (error) {
      console.error(error)
    }
  }

  const handleRemoveClient = async() => {
    try {
      let answer = confirm(`Are you sure you want to delete this client?`)
      if(answer) 
        await httpService(`DELETE`,`/companies/${client.id}`)
      toast(`Succesfully removed client.`,successStyle)
      history.push(`/clients`)
    }
    catch(error){toast(`Failed to delete client.`,errorStyle)}
  }

  return (
    <MainLayout>
      <Container>
        {!client && <Spinner/>}
        {client && 
        <>
          <Form 
            values={state}
            setValues={setState}
            jsonSchema={clientSchema}
            css={`width:50%;`}/>
          <ButtonContainer>
            <Button onClick={handleSubmit}>Submit</Button>  
            <Button 
              onClick={handleRemoveClient} 
              css="margin-left: 20px">
              Delete Client
            </Button>      
          </ButtonContainer>
          <ProjectsTable projects={clientProjects} clients={[client]} />
        </>}
      </Container>
    </MainLayout>
  )
}

export default Client

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: clamp(250px,60vw,1000px);
  max-width: 1000px;
  color: ${darkgray};
  padding: 20px;
`
const Header = styled.h1`
  font-size: 3em;
  font-weight: 700;
  ::first-letter{
    color: ${green};
  }
`

const ButtonContainer = styled.div`
  padding: 20px;
  display: flex;
`