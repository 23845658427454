import moment from 'moment'
import React,{ useEffect,useState } from 'react'
import styled from 'styled-components'
import { darkgray } from '../utils/colours'
import { sortArrayAlphabeticallyByName } from '../utils/sort'
import DayProjectList from './dayProjectList'
import MainLayout from './mainLayout'
import Search from './search'
import Spinner from './spinner'
import { useUser } from '../hooks/useUser'
import LoginButton from './LoginButton.js'
import { useDay } from '../hooks/useDay'
import { useApi } from '../hooks/useApi.js'


const Today = () => {
  const [day] = useDay()
  const [searchTerm,setSearchTerm] = useState(``)
  const [filteredProjects,setFilteredProjects] = useState(null)
  const user = useUser()
  let projects = useApi(`/projects`) || []
  const times = useApi(`/times?employee_id=${user.id}`) || []

  projects = sortArrayAlphabeticallyByName(projects)

  useEffect(() => {
    if(searchTerm || searchTerm === ``) filterSearch()
  },[searchTerm])
 
  const filterSearch = () => {
    const filteredProjects = projects.filter(project => {
      const str = project.name.toLowerCase()
      const bool = str.includes(searchTerm)
      if(bool) return project
    })
    setFilteredProjects(filteredProjects)
  }

  return (
    <MainLayout>
      <Container>
        <Content>
          <TitleBox>
            <DayHeader>{moment(day).format(`DD MMM YYYY `)}</DayHeader>
            {!!projects.length && <Search backgroundColor={`#b8b8b81d`} onChange={word => setSearchTerm(word)} />}
          </TitleBox>
          {user.email && projects.length === 0 && <Spinner />}
          {!!projects.length && 
            <DayProjectList projects={filteredProjects != `` ? filteredProjects : projects} day={moment()}/>
          }
          <div style={{ marginTop: 20,fontWeight: 800 }}>
            Total: {times
              .filter(t => t.email === user.email)
              .filter(t => moment(t.date).isSame(moment(),`date`))
              .map(t => t.hours)
              .reduce((acc,cur) => acc + cur,0)
            } hours</div>
        </Content>
      </Container>
    </MainLayout>
  )
}

export default Today

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items:center;
  max-width: 1000px;
  color: ${darkgray};
  height:100vh;
  gap:20px;
  padding: 20px;
`
const TitleBox = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  text-align: center;
  flex-wrap: wrap;
  padding: 5px;
  margin-bottom: 1rem;
  @media screen and (max-width: 600px){
    margin-bottom: 3vw;
  }
  
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 600px; 
  @media screen and (max-width: 600px){
    max-width: 200px;
  }
`
// const Header = styled.h1`
//   text-align: center;
//   margin-top: 10px;
//   margin-bottom: 0;
//   padding: 5px 0 5px 15px;
//   border-top-left-radius: 10px;
//   border-bottom-left-radius: 10px;
//   font-size: 3em;
//   font-weight: 700;
//   border-left: 10px solid ${lightpurple};
//   span{
//     color: ${lightpurple};
//   }
// `
const DayHeader = styled.div`
  width: 50%;
  align-items: center;
  flex-grow: 0;
  font-size: 1.5em;
  /* font-weight: 400; */
`
